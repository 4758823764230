@import url(../../library/src/index.css);

html * {
  font-size: 17px;
  font-family: "Baloo 2", sans-serif;
}

.verifyInputContainer {
  display: flex;
  gap: 15px;
}

.verifyInput {
  display: flex;
  height: 70;
  width: 70;
  align-items: center;
  justify-content: center;
  font-size: 36;
}

.verifyInputSelected {
  color: black;
  outline: 2px solid black;
}
